.contattaci-contattaci-button {
  flex: 0 0 auto;
  width: 373px;
  height: 130px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  border-radius: 25px;
  justify-content: center;
  background-color: #daff41;
}
.contattaci-text94 {
  color: #8815ac;
  font-family: Mongoose;
  font-size: 60px;
}

@media (max-width: 479px) {
  .contattaci-contattaci-button {
    width: 70%;
    height: 100px;
  }
}

.about-us-container {
  width: 100%;
  height: 1520px;
  display: flex;
  position: relative;
  transform: skewY(-2deg);
  margin-top: -100px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #8815ac;
  transform-origin: top right;
}
.about-us-striscia-sopra-about {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  background-color: #dafd41;
}
.about-us-aboutus {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus01 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus02 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus03 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus04 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus05 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus06 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus07 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus08 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus09 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus10 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus11 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus12 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus13 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus14 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus15 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus16 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus17 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus18 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus19 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus20 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus21 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus22 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus23 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus24 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus25 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus26 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus27 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus28 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus29 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus30 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus31 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus32 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus33 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus34 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus35 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus36 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus37 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus38 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-aboutus39 {
  color: rgb(0, 88, 132);
  font-size: 25px;
  font-style: normal;
  font-family: Mongoose;
  font-weight: 800;
  white-space: nowrap;
}
.about-us-about-us-div1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  transform: skewY(2deg);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  background-size: auto;
  justify-content: center;

  background-repeat: no-repeat;
  background-position:
    80% 20%,
    80% 80%,
    20% 20%;
}
.about-us-about-child1 {
  flex: 0 0 auto;
  width: 70%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.about-us-text80 {
  font-size: 80px;
  font-family: Mongoose;
  line-height: 1.2;
}
.about-us-text81 {
  color: rgba(251, 251, 255, 0.8);
}
.about-us-text82 {
  color: rgba(251, 251, 255, 0.8);
  font-family: Mongoose;
}
.about-us-text83 {
  color: rgba(251, 251, 255, 0.8);
}
.about-us-text84 {
  color: rgba(251, 251, 255, 0.8);
  font-family: Mongoose;
}
.about-us-text85 {
  color: rgba(251, 251, 255, 0.8);
}
.about-us-text86 {
  color: #005884;
  background-color: rgb(218, 255, 65);
}
.about-us-text87 {
  color: rgba(251, 251, 255, 0.8);
}
.about-us-text88 {
  color: #005884;
  background-color: rgb(218, 255, 65);
}
.about-us-text89 {
  color: rgba(251, 251, 255, 0.8);
}
.about-us-text90 {
  color: #005884;
  background-color: rgb(218, 255, 65);
}
.about-us-text91 {
  color: rgba(251, 251, 255, 0.8);
}

@media (max-width: 1200px) {
  .about-us-about-us-div1 {
    background-size: contain;
    background-position: center;
  }
}
@media (max-width: 850px) {
  .about-us-text80 {
    font-size: 60px;
  }
}
@media (max-width: 479px) {
  .about-us-about-child1 {
    width: 90%;
    margin-top: 50px;
  }
  .about-us-text80 {
    font-size: 42px;
  }
  .about-us-container {
    height: 900px;
  }
}
@media (min-width: 1350px) {
  .about-us-container {
    width: 100%;
    height: 85vw;
    display: flex;
    position: relative;
    transform: skewY(-2deg);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #8815ac;
    transform-origin: top right;
  }
}
@media (min-width: 1530px) {
  .about-us-container {
    width: 100%;
    height: 60vw;
    display: flex;
    position: relative;
    transform: skewY(-2deg);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #8815ac;
    transform-origin: top right;
  }
}
@media (min-width: 1800px) {
  .about-us-container {
    width: 100%;
    height: 50vw;
    display: flex;
    position: relative;
    transform: skewY(-2deg);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #8815ac;
    transform-origin: top right;
  }
}

@media (min-width: 2000px) {
  .about-us-container {
    width: 100%;
    height: 45vw;
    display: flex;
    position: relative;
    transform: skewY(-2deg);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #8815ac;
    transform-origin: top right;
  }
}
@media (min-width: 2300px) {
  .about-us-container {
    width: 100%;
    height: 35vw;
    display: flex;
    position: relative;
    transform: skewY(-2deg);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #8815ac;
    transform-origin: top right;
  }
}
