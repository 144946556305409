.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fbfbff;
}

.home-case-story {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  justify-content: center;
  position: relative;
  margin-top: -300px;
  margin-bottom: -190px;
}

.image-slider-container {
  position: relative;
}

.home-scroll-button {
  position: fixed;
  display: flex;
  right: 20px;
  top: 50%;
  width: 114px;
  height: 114px;
  transition: opacity 0.5s ease-out, max-height 0.5s ease-out;
  max-height: 114px;
  /* Set this to the height of your button or larger */
  z-index: 10;
  align-items: flex-start;
  background-size: cover;
  background-image: url("/playground_assets/raggruppa%2051-200h.png");
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-50%) scale(0);
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  visibility: hidden;
}

.home-scroll-button.hidden {
  opacity: 0;
  visibility: hidden;
  /* This makes sure the button doesn't interfere with user interactions */
}

.home-scroll-button.visible {
  transform: translateY(-50%) scale(1);
  opacity: 1;
  visibility: visible;
}

@keyframes scrollAnimation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 0% 4000px;
  }
}

.loading-container.scroll-animation {
  animation: scrollAnimation 4s linear infinite;
  background-repeat: repeat-y;
  background-size: 100% auto;
}

@media (max-width: 868px) {
  .loading-container.scroll-animation {
    animation: scrollAnimation 8s linear infinite;
    background-repeat: repeat-y;
    background-size: 100% auto;
  }
}

@media (max-width: 668px) {
  .loading-container.scroll-animation {
    animation: scrollAnimation 10s linear infinite;
    background-repeat: repeat-y;
    background-size: 100% auto;
  }
}

@media (max-width: 408px) {
  .loading-container.scroll-animation {
    animation: scrollAnimation 12s linear infinite;
    background-repeat: repeat-y;
    background-size: 100% auto;
  }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #8815ac;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: hidden;
  background-image: url("/playground_assets/raggruppa 981-1600h.png");
  background-repeat: repeat;
  background-position: center;
}

.loading-container.animation-complete {
  transition: transform 1.5s cubic-bezier(0.87, 0, 0.13, 1);
  transform: translateY(-100%);
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.loading-text {
  font-family: "Mongoose", cursive;
  font-size: 4rem;
  text-transform: uppercase;
  display: flex;
  color: #daff41;
  margin-bottom: -100px;
  margin-top: 100px;
}

.letter {
  opacity: 0;
  animation: compose-letter 1s forwards cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.first-timeout .letter:nth-child(1) {
  animation-delay: 0.1s;
}

.first-timeout .letter:nth-child(2) {
  animation-delay: 0.2s;
}

.first-timeout .letter:nth-child(3) {
  animation-delay: 0.3s;
}

.first-timeout .letter:nth-child(4) {
  animation-delay: 0.4s;
}

.first-timeout .letter:nth-child(5) {
  animation-delay: 0.5s;
}

.first-timeout .letter:nth-child(6) {
  animation-delay: 0.6s;
}

.second-timeout .letter:nth-child(1) {
  animation-delay: 0.6s;
}

.second-timeout .letter:nth-child(2) {
  animation-delay: 0.7s;
}

.second-timeout .letter:nth-child(3) {
  animation-delay: 0.8s;
}

.second-timeout .letter:nth-child(4) {
  animation-delay: 0.9s;
}

.second-timeout .letter:nth-child(5) {
  animation-delay: 1s;
}

.second-timeout .letter:nth-child(6) {
  animation-delay: 1.1s;
}

@keyframes compose-letter {
  0% {
    opacity: 0;
    transform: translateY(30px) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0) rotate(0);
  }
}

.loading-bar-container {
  width: 80%;
  height: 20px;
  background-color: #005884;
  margin-top: 30px;
  position: relative;
}

.loading-bar {
  height: 100%;
  background-color: #daff41;
  position: relative;
  transition: width 0.1s ease-out;
}

.percentage {
  position: absolute;
  right: 0;
  top: -24px;
  font-size: 14px;
  font-weight: bold;
  color: #daff41;
}

.home-scroll-button-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .home-scroll-button {
    width: 40px;
    height: 40px;
    right: 10px;
    display: none;
    /* Adjust this value as needed */
  }
}
@media (max-width: 768px) {
  .home-case-story {
    overflow-x: hidden;
    margin-left: 0px;
  }
}
